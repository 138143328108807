import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import useScreenRecognition from 'hooks/useScreenRecognition';
import algoliasearch from 'algoliasearch/lite';
import InstantSearch from 'react-instantsearch-core/dist/es/widgets/InstantSearch';
import useShopifyHelper from 'hooks/useShopifyHelper';
import { ProductCardListWithFilterProps } from './models';
import './ProductCardtList.scss';
import Filters from '../Filters';
import ProductCardListWithHits from './ProductCardListWithHits';
import ProductCardList from './ProductCardList';

// eslint-disable-next-line
const GATSBY_ALGOLIA_SEARCH_QUERY_INDEX = process.env.GATSBY_ALGOLIA_SEARCH_QUERY_INDEX;

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

const ProductCardListWithFilters: FC<ProductCardListWithFilterProps> = ({
  limit = 0,
  limitMobile = 0,
  btnTitle = '',
  ariaMore = '',
  products,
  productListingSettings: { showing = '', titlePlaceholder = '' },
  filterList,
  isBundles,
  isSorting,
  sortingOptions,
  sortPlaceholder,
  clearAllButtonText,
  lang,
  isShopify,
  title,
  usePriceSpider,
  isEanProductId,
}) => {
  const searchClient = {
    search(requests) {
      const shouldSearch = requests.some(({ params: { tagFilters } }) => tagFilters.length > 0);
      if (shouldSearch) {
        return algoliaClient.search(requests);
      }

      return Promise.resolve({
        results: [{ hits: products.nodes }],
      });
    },
    searchForFacetValues: algoliaClient.searchForFacetValues,
  };

  const indexName = `${GATSBY_ALGOLIA_SEARCH_QUERY_INDEX}-${isBundles ? 'bundles' : 'products'}`;

  const { isNotMobile } = useScreenRecognition();
  const [total, setTotal] = useState(0);
  const [toShow, setToShow] = useState<number>(limitMobile);
  const [selectedSorting, setSelectedSorting] = useState('');

  const stepLoad = useMemo(
    () => (isNotMobile ? Math.min(limit, total) : Math.min(limitMobile, total)),
    [limit, limitMobile, total, isNotMobile]
  );

  const lessCountValue = useMemo(
    () => (!isBundles && isNotMobile ? 3 : 2),
    [isBundles, isNotMobile]
  );

  const { mergeCmsShopifyProducts } = useShopifyHelper({ isShopify, lang });

  const dropdownSortingOptions = useMemo(
    () =>
      sortingOptions?.reduce((acc, option) => {
        const { ascending, descending } = option.properties;
        const optionsArr = [ascending, descending];

        return optionsArr.reduce(
          (optionAcc, sortOption) =>
            sortOption ? [...optionAcc, { title: sortOption, value: sortOption }] : optionAcc,
          acc
        );
      }, []),
    []
  );

  const productsWithPrices = mergeCmsShopifyProducts(products);

  const filtersToDraw = filterList?.[0]?.properties?.filters?.map((item) => item.properties);
  const buttonTitle = filterList?.[0]?.properties?.buttonTitle;

  const loadMore = useCallback(() => {
    const count = total - (toShow + stepLoad) <= lessCountValue ? total : toShow + stepLoad;
    setToShow(count);
  }, [total, toShow, stepLoad]);

  const showingString = showing
    .replace(/%total%/i, `<span>${total}</span>`)
    .replace(/%number%/i, `<span>${toShow}</span>`);

  useEffect(() => {
    const defaultOption = sortingOptions?.find((option) => Boolean(option.properties?.isDefault));

    if (defaultOption) {
      const defaultSortingDirection = defaultOption.properties?.defaultOption
        ? defaultOption.properties?.ascending
        : defaultOption.properties?.descending;
      setSelectedSorting(defaultSortingDirection);
    }
  }, []);

  useEffect(() => {
    const count = total - stepLoad <= lessCountValue ? total : stepLoad;
    setToShow(count);
  }, [total]);

  const handleResetToShow = () => {
    setToShow(stepLoad);
  };

  const sortedList = useMemo(() => {
    const result = [...(productsWithPrices || [])];

    result.sort((a, b) => {
      if (!isSorting || !selectedSorting) {
        if (!a.position && !b.position) {
          return 0;
        }
        if (!a.position || !b.position) {
          return -1;
        }
      }

      const sortingOption = sortingOptions?.find((option) =>
        Object.values(option.properties).includes(selectedSorting)
      )?.properties;

      if (sortingOption) {
        const sortingName = sortingOption?.optionName[0];

        const sortingDirection = Object.entries(sortingOption)?.find(
          (property) => property[1] === selectedSorting
        )?.[0];

        if (sortingName && sortingDirection) {
          const prevProductCard =
            typeof a[sortingName] === 'string'
              ? a[sortingName].replace(/[^\d.]/g, '').split('-')[0]
              : a[sortingName];
          const nextProductCard =
            typeof b[sortingName] === 'string'
              ? b[sortingName].replace(/[^\d.]/g, '').split('-')[0]
              : b[sortingName];
          if (sortingDirection === 'ascending') {
            return prevProductCard - nextProductCard;
          }

          if (sortingDirection === 'descending') {
            return nextProductCard - prevProductCard;
          }
        }
      }

      return a.position - b.position;
    });

    return result.sort((a, b) => {
      if (!a.availableQuantity) return 1;

      if (!b.availableQuantity) return -1;

      return 0;
    });
  }, [JSON.stringify(productsWithPrices), selectedSorting, isSorting]);

  useEffect(() => {
    if (!filtersToDraw?.length) {
      setTotal(sortedList.length);
    }
  }, [sortedList.length]);

  return (
    sortedList?.length > 0 &&
    (filtersToDraw?.length > 0 || sortingOptions?.length > 0 ? (
      <InstantSearch indexName={indexName} searchClient={searchClient}>
        <Filters
          clearAllButtonText={clearAllButtonText}
          sortPlaceholder={sortPlaceholder}
          sortingOptions={dropdownSortingOptions}
          onDropdownChange={(sortingOption) => setSelectedSorting(sortingOption)}
          dropDownValue={selectedSorting}
          filters={filtersToDraw}
          buttonTitle={buttonTitle}
          handleResetToShow={handleResetToShow}
          lang={lang}
        />
        <ProductCardListWithHits
          sortedList={sortedList}
          sortPlaceholder={sortPlaceholder}
          dropdownSortingOptions={dropdownSortingOptions}
          setSelectedSorting={setSelectedSorting}
          setTotal={setTotal}
          selectedSorting={selectedSorting}
          btnTitle={btnTitle}
          ariaMore={ariaMore}
          showingString={showingString}
          toShow={toShow}
          loadMore={loadMore}
          isBundles={isBundles}
          isShopify={isShopify}
          lang={lang}
          cartProductsData={products}
          title={title}
          titlePlaceholder={titlePlaceholder}
          usePriceSpider={usePriceSpider}
          isEanProductId={isEanProductId}
        />
      </InstantSearch>
    ) : (
      <ProductCardList
        sortPlaceholder={sortPlaceholder}
        sortingOptions={dropdownSortingOptions}
        onDropdownChange={(sortingOption) => setSelectedSorting(sortingOption)}
        dropDownValue={selectedSorting}
        btnTitle={btnTitle}
        ariaMore={ariaMore}
        showingString={showingString}
        productsToShow={sortedList}
        toShow={toShow}
        loadMore={loadMore}
        isBundles={isBundles}
        isShopify={isShopify}
        lang={lang}
        cartProductsData={products}
        title={title}
        titlePlaceholder={titlePlaceholder}
        usePriceSpider={usePriceSpider}
        isEanProductId={isEanProductId}
      />
    ))
  );
};

export default ProductCardListWithFilters;

export const query = graphql`
  fragment FragmentProductCardList on TProductListing {
    properties {
      filterList {
        ... on TFilterList {
          ...FragmentFilterList
        }
      }
      limit
      limitMobile
      btnTitle
      ariaMore
      isSorting
      sortingOptions {
        properties {
          ascending
          descending
          optionName
          isDefault
          defaultOption
        }
      }
      sortPlaceholder
      title
      clearAllButtonText
    }
    structure
  }
`;
