import React, { FC, useEffect, useMemo } from 'react';
import connectHits from 'react-instantsearch-core/dist/es/connectors/connectHits';
import ProductCardList from './ProductCardList';
import { IProductCardListWithHits } from './models';

const FilteredProductCardList: FC<IProductCardListWithHits> = ({
  sortPlaceholder,
  hits,
  sortedList,
  dropdownSortingOptions,
  setSelectedSorting,
  setTotal,
  selectedSorting,
  btnTitle,
  ariaMore,
  showingString,
  toShow,
  loadMore,
  isBundles,
  isShopify,
  lang,
  cartProductsData,
  title,
  titlePlaceholder,
  usePriceSpider,
  isEanProductId,
}) => {
  const productsToShow = useMemo(
    () =>
      hits
        ? sortedList.filter((productNode) => hits.find((hit) => productNode.link === hit.link))
        : sortedList,
    [JSON.stringify(hits), JSON.stringify(sortedList)]
  );

  useEffect(() => {
    setTotal(productsToShow.length);
  }, [productsToShow.length]);

  return (
    <ProductCardList
      sortPlaceholder={sortPlaceholder}
      sortingOptions={dropdownSortingOptions}
      onDropdownChange={(sortingOption) => setSelectedSorting(sortingOption)}
      dropDownValue={selectedSorting}
      btnTitle={btnTitle}
      ariaMore={ariaMore}
      showingString={showingString}
      productsToShow={productsToShow}
      toShow={toShow}
      loadMore={loadMore}
      isBundles={isBundles}
      isShopify={isShopify}
      lang={lang}
      cartProductsData={cartProductsData}
      title={title}
      titlePlaceholder={titlePlaceholder}
      usePriceSpider={usePriceSpider}
      isEanProductId={isEanProductId}
    />
  );
};

const ProductCardListWithHits = connectHits(FilteredProductCardList);

export default ProductCardListWithHits;
