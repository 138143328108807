import React, { FC, useRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import './ProductCardtList.scss';
import CustomDropdown from 'components/CustomDropdown';
import { Container } from '../../layout';
import DangerouslySetInnerHtml from '../../common/DangerouslySetInnerHtml';
import ProductCard from '../ProductCard';
import Button from '../../common/Button';
import { ProductCardListProps } from './models';

const ProductCardList: FC<ProductCardListProps> = ({
  btnTitle = '',
  ariaMore = '',
  showingString = '',
  productsToShow = [],
  toShow,
  loadMore,
  isBundles,
  sortingOptions,
  onDropdownChange,
  dropDownValue,
  sortPlaceholder,
  isShopify,
  lang,
  cartProductsData,
  title,
  titlePlaceholder,
  usePriceSpider,
  isEanProductId,
}) => {
  const ref = useRef(null);
  const [prevShowCount, setPrevShowCount] = useState(0);

  useEffect(() => {
    setPrevShowCount(toShow);
  }, [toShow]);

  const handleLoadMore = () => {
    loadMore();
    setTimeout(() => {
      ref.current?.children[prevShowCount]
        ?.getElementsByClassName('product-card__link')?.[0]
        ?.focus();
    }, 0);
    if (usePriceSpider) {
      window?.PriceSpider?.rebind();
    }
  };

  return (
    <div
      className={classnames('product-cards-list', {
        'product-cards-list--bundles-theme': isBundles,
      })}
      data-testid="product-cards-list"
    >
      <Container>
        {title ? <h2 className="sr-only">{title}</h2> : null}
        {productsToShow?.length === 0 ? (
          <div className="product-cards-list__no-items-text">There are no items to show</div>
        ) : (
          <>
            <div className="product-cards-list__quantity-and-sorting">
              <DangerouslySetInnerHtml
                className="product-cards-list__showing"
                html={showingString}
                role="status"
                aria-live="polite"
              />
              <CustomDropdown
                dropDownList={sortingOptions}
                onChange={onDropdownChange}
                value={dropDownValue}
                sortPlaceholder={sortPlaceholder}
              />
            </div>
            <ul ref={ref} className="product-cards-list__grid">
              {productsToShow?.slice(0, toShow).map((product) => (
                <li key={product.title}>
                  <ProductCard
                    {...{
                      ...product,
                      isBundles,
                      isShopify,
                      lang,
                      cartProductsData,
                      titlePlaceholder,
                      usePriceSpider,
                      isEanProductId,
                    }}
                  />
                </li>
              ))}
            </ul>
            {productsToShow.length > 0 && btnTitle && toShow < productsToShow.length ? (
              <div className="product-cards-list__more">
                <Button
                  ariaLabel={ariaMore}
                  clickHandler={handleLoadMore}
                  classes="product-cards-list__btn"
                  variant="secondary"
                >
                  {btnTitle}
                </Button>
              </div>
            ) : null}
          </>
        )}
      </Container>
    </div>
  );
};

export default ProductCardList;
